import * as React from 'react';
import { Button, Layout, Seo } from '../components';
import './style.scss';

const NotFoundPage: React.FC = () => (
    <Layout isNotFound={true}>
        <Seo title="Page Not found" description="Error 404 - Page not found" />
        <div style={{ padding: '254px 16px 180px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh', width: '100%', backgroundImage: `url('/images/404-bg.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <p className="big">404</p>
                <p className="bold" style={{ color: 'white', fontSize: 28, textAlign: 'center', fontFamily: 'Proxima Nova' }}>You dove too deep! Let’s get you<br />back to the surface.</p>
                <Button as='a' href="/">Okay</Button>
                <p style={{ color: 'white', fontFamily: 'Proxima Nova', textAlign: 'center' }}>© Pacific Catch, Inc. Website caught by <a style={{ fontWeight: 'bold', color: 'white', textDecoration: 'none' }} href='https://www.aglasshalf.co.uk'>A Glass Half</a></p>
        </div>
    </Layout>
);

export default NotFoundPage;
